export enum Device {
	Desktop = 0,
	Mobile = 1,
	Amp = 2
}

export const DeviceTitle = {
	[Device.Desktop]: 'Desktop',
	[Device.Mobile]: 'Mobile',
	[Device.Amp]: 'AMP',
};

export const DeviceIcon = {
	[Device.Desktop]: 'fa-television',
	[Device.Mobile]: 'fa-mobile fa-lg',
	[Device.Amp]: 'fa-flash',
};
