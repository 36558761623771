import React from 'react';

import '@commonTuna/react/scss/components/logoLoader.scss';

interface IProps {
	size?: number;
	withScale?: number;
}

const LogoLoader: React.FC<IProps> = ({ size = 60, withScale = 1 }) => {
	const len = size * Math.PI;
	const strokeWidth = `${size * (withScale / 30)}`;

	return (
		<div className="logo-loader-container">
			<svg className="logo-loader" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
				<circle
					className="first"
					r={`${50 - (100 * (withScale / 30))}%`}
					cx="50%"
					cy="50%"
					style={{
						strokeDasharray: `${0.78 * len} ${len}`,
						strokeDashoffset: `-${0.22 * len}`,
						strokeWidth,
					}}
				/>
				<circle
					className="second"
					r={`${40 - (100 * (withScale / 30))}%`}
					cx="50%"
					cy="50%"
					style={{
						strokeDasharray: `${0.77 * len * (38 / 50)} ${len * (3 / 5)}`,
						strokeDashoffset: `-${0.23 * len * (38 / 50)}`,
						strokeWidth,
					}}
				/>
			</svg>
		</div>
	);
};

export const LogoLoaderPage: React.FC<IProps> = (props) => {
	return (
		<div className="loading-component page-loading">
			<LogoLoader {...props} />
		</div>
	);
};

export default LogoLoader;
